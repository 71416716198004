<template>
  <v-container>
    <v-row class="pt-10">
      <!-- Loop through each product -->
      <v-col class="px-2 mx-0" v-for="(product, index) in products" :key="index" cols="12" md="4" lg="4">
        <v-card outlined>
          <v-row>
            <!-- Image Section -->
            <v-col cols="4" class="d-flex justify-center align-center">
              <v-img :src="product.image" max-width="100px"></v-img>
            </v-col>

            <!-- Content Section -->
            <v-col cols="8">
              <v-card-title class="text-h6">{{ product.title }}</v-card-title>
              <v-card-subtitle>{{ product.description }}</v-card-subtitle>

              <!-- Quantity Control and Availability -->
              <v-row class="d-flex align-center">
                <v-col cols="6" class="d-flex align-center">
                  <v-btn :disabled="product.quantity == 0" icon @click="decreaseQuantity(index)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-text-field v-model="product.quantity" :max="product.availableStock" class="mx-2" style="max-width: 50px;"></v-text-field>
                  <v-btn :disabled="product.quantity >= product.availableStock" icon @click="increaseQuantity(index)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="6" class="d-flex align-center">
                  <span v-bind:class="product.availableStock > 0 ? 'mr-2 success--text' : 'mr-2 error--text'">Available:</span>
                  <strong v-bind:class="product.availableStock > 0 ? 'mr-2 success--text' : 'mr-2 error--text'">{{ product.availableStock }}</strong>
                </v-col>
              </v-row>

              <!-- Add to Cart Button -->
              <v-card-actions>
                <v-btn color="green" @click="addToCart(index)">
                  Add To Cart
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          title: "Standard Laptop",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          image: "https://via.placeholder.com/100",
          quantity: 0,
          availableStock: 1,
        },
        {
          title: "Executive Laptop",
          description:
            "Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
          image: "https://via.placeholder.com/100",
          quantity: 0,
          availableStock: 2,
        },
        {
          title: "Gaming Laptop",
          description:
            "Duis vulputate commodo lectus, ac blandit elit tincidunt id.",
          image: "https://via.placeholder.com/100",
          quantity: 0,
          availableStock: 0,
        },
      ],
    };
  },
  methods: {
    increaseQuantity(index) {
      if (this.products[index].quantity < this.products[index].availableStock) {
        this.products[index].quantity++;
      }
    },
    decreaseQuantity(index) {
      if (this.products[index].quantity > 0) {
        this.products[index].quantity--;
      }
    },
    addToCart(index) {
      const product = this.products[index];
      alert(`Added ${product.quantity} of ${product.title} to the cart`);
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
